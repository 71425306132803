import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import COMPONENTS from '../../../bi/constants/components';

import styles from '../styles/index.module.css';

const LABELS = {
  NO: 'Нет',
  YES: 'Да',
};

const WIDTH = {
  DEFAULT: '400px',
};

const ModalDialog = ({
  showDialog,
  onHandleCloseDialog,
  text,
  onHandleRemove,
}) => {
  if (!showDialog) {
    return null;
  }

  return (
    <Dialog
      onClick={ onHandleCloseDialog }
      showCloseButton
      width={ WIDTH.DEFAULT }
    >
      <div className={ styles.modal_wrap }>
        <div className={ styles.modal_content }>
          { text }
          <div className={ styles.modal_buttons }>
            <Button
              onClick={ onHandleCloseDialog }
              theme={ COMPONENTS.BUTTON.THEME.SECOND }
              className={ styles.modal_button }
            >
              { LABELS.NO }
            </Button>
            <Button
              onClick={ onHandleRemove }
              theme={ COMPONENTS.BUTTON.THEME.MAIN }
              className={ styles.modal_button }
            >
              { LABELS.YES }
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

ModalDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onHandleCloseDialog: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onHandleRemove: PropTypes.func.isRequired,
};

export { ModalDialog };
