import React from 'react';
import PropTypes from 'prop-types';

import Circle from '../../../../components/Circle';

import styles from '../../styles/index.module.css';

const OperationItem = ({ serviceOperationNames }) => (
  <div className={ styles.block }>
    { serviceOperationNames.map(item => (
      <div key={ item.name } className={ styles.item }>
        <Circle added={ item.added } />
        <div>{ item.name }</div>
      </div>
      )) }
  </div>
);

OperationItem.propTypes = {
  serviceOperationNames: PropTypes.array.isRequired,
};

export { OperationItem };
