import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import { Button, Tooltip } from 'sw-ui';

import EmploeeRights from './EmployeeRights';
import EmployeeTP from './EmployeeTP';
import EmployeeDocuments from './EmployeeDocuments';
import EmployeeTrips from './EmployeeTrips';
import EmployeeScheme from './EmployeeScheme';
import EmployeeBonusCards from './EmployeeBonusCards';
import StickyPanel from '../../components/Sticky';
import CircularLoaders from '../../components/loaders';
import CommentComponent from '../../components/Comment';
import LinkToNewTab from '../../components/LinkToNewTab';

import { getCountryName, getShortName } from '../../bi/utils/employee';
import MoneyFormat from '../../bi/utils/money';

import { ACCOUNTTEXT, COMMENTS } from '../../bi/constants/account';
import { TYPES_ICON_LIST, ANCHORS, TITLE, ENUMS } from '../../bi/constants/employee';
import { BUYTRIPSACCOUNTRIGHT } from '../../bi/constants/rights';
import COMPONENTS from '../../bi/constants/components';

import styles from './styles/employee.module.scss';

const LABELS = {
  HEAD: 'руководитель',
  COMPANY_NAME_DEBIT: companyNameBooking => `${companyNameBooking} (Задолженность)`,
  COMPANY_NAME_BOOKING: (companyNameBooking, AvailableAmount) => `${companyNameBooking} (Доступно для бронирования: ${MoneyFormat.moneyWithDecimal(AvailableAmount)})`,
};

const maxCommentLength = 1000;
const commentPlaceholder = `Введите заметку не более ${maxCommentLength} символов`;

class EmployeeComponent extends Component {
  static propTypes = {
    travelPolicyService: PropTypes.object.isRequired,
    employeeService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    rightsService: PropTypes.object.isRequired,
    approvalService: PropTypes.object.isRequired,
    enumsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      employee: {},
      approversForEmployee: [],
      travelPolicy: null,
      rights: null,
      bonusCodes: {},
      trips: [],
      projects: [],
      citizenship: [],
      approvalScheme: null,
      editing: false,
      notes: '',
      waitingResponse: false,
      approvalSchemaER: {},
    };

    this.blockRefs = {};
  }

  componentDidMount() {
    this.unsubscribeEmployeeServiceCb = this.props.employeeService.subscribe(this.updateEmployee);
    this.loadEmployeeData();
  }

  componentWillUnmount() {
    if (this.unsubscribeEmployeeServiceCb) this.unsubscribeEmployeeServiceCb();
  }

  updateEmployee = ({ employee }) => {
    this.setState({
      loading: false,
      employee,
    });
  };

  loadEmployeeData = async () => {
    await this.getEnums();
    const { appService, employeeService } = this.props;
    const { employeeId } = appService.get().params;
    await employeeService.getEmployee(employeeId);
    await this.getTP(employeeId);
    await this.getProjects();
    await this.getRightsAndApprovalScheme(employeeId);
    await this.getTrips(employeeId);
  }

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  setRef = (id, ref) => {
    this.blockRefs[id] = ref;
  }

  handleScroll = id => () => {
    const ref = this.blockRefs[id];
    if (!ref) {
      return;
    }
    window.scroll({ top: ref.offsetTop - 40, left: 0, behavior: 'smooth' });
  }

  getTP = async (id) => {
    const { travelPolicyService } = this.props;
    try {
      const tP = await travelPolicyService.getEmployeeTP(id);
      const tPDetails = await travelPolicyService.getEveryoneTPDetails(tP);
      this.setState({ travelPolicy: tPDetails });
    } catch (e) {
      this.setState({ travelPolicy: null });
    }
  }

  getTrips = async (employeeId) => {
    const { employeeService } = this.props;
    const { employee: { Companies } } = this.state;
    const { CompanyId } = Companies[0];
    await employeeService.getEmployeeTrips(CompanyId, employeeId);
    const { trips } = employeeService.get();
    this.setState({ trips });
  }

  getProjects = async () => {
    const { employeeService } = this.props;
    const { employee: { AccountId } } = this.state;
    await employeeService.getProjects(AccountId);
    const { projects } = employeeService.get();
    this.setState({ projects });
  }

  getRightsAndApprovalScheme = async (id) => {
    const { rightsService, approvalService } = this.props;
    const { employee: { AccountId } } = this.state;
    const rights = await rightsService.getEmployeeRights(AccountId, id);
    const approvalScheme = await approvalService.getApprovalScheme(id);
    const сreateReportsApproval = await approvalService.getCreateReportsApproval(id);
    const approvalSchemaER = await approvalService.getApprovalSchemaER(rights.UserId);
    let approversForEmployee = await approvalService.getApproversForUser(id);

    if (rights.BuyTripPersonal === BUYTRIPSACCOUNTRIGHT.OnlyAfterApprove && !approversForEmployee.length) {
      approversForEmployee = await approvalService.getAllApproversForUser(id);
    }

    this.setState({ rights, approvalScheme, approversForEmployee, сreateReportsApproval, approvalSchemaER });
  }

  handleEditing = () => this.setState({
    notes: this.state.employee.Notes,
    editing: true,
  });

  closeEditing = () => this.setState({
    editing: false,
  });

  handleSave = () => {
    const { employeeService } = this.props;
    const { employee: { Id }, notes } = this.state;

    employeeService.updateNotes(Id, notes).then(() => {
      this.setState({
        editing: false,
        employee: {
          ...this.state.employee,
          Notes: notes,
        },
      });
    });
  };

  handleCompanyName = (ShortCompanyName, CompanyName, HasDebt, AvailableAmount) => {
    const companyNameBooking = ShortCompanyName || CompanyName;

    if (HasDebt) {
      return LABELS.COMPANY_NAME_DEBIT(companyNameBooking);
    }

    return LABELS.COMPANY_NAME_BOOKING(companyNameBooking, AvailableAmount);
  }

  getEnums = async () => {
    const { enumsService } = this.props;
    const enums = await enumsService.getEnums([ENUMS.BONUS, ENUMS.CITIZENSHIP]);
    const { bonus, citizenship } = enums;
    this.setState({ bonusCodes: bonus || [], citizenship: citizenship || [] });
  }

  renderAddIcon = (status, canLogin) => {
    const icons = [];
    const text = [];
    if (status === 'Archived') {
      icons.push(TYPES_ICON_LIST.ARCHIVED.TYPEICON);
      text.push(TYPES_ICON_LIST.ARCHIVED.TOOLTIPTEXT);
    }
    if (canLogin) {
      icons.push(TYPES_ICON_LIST.CAN_LOGIN.TYPEICON);
      text.push(TYPES_ICON_LIST.CAN_LOGIN.TOOLTIPTEXT);
    }
    if (!icons.length) {
      return null;
    }

    const iconsHtml = icons.map(typeIcon => (
      <span key={ typeIcon } className={ styles.wrap_icons }>
        <i className={ `material-icons ${styles.icons}` } >
          { typeIcon }
        </i>
      </span>
    ));
    return (
      <span>
        {iconsHtml}
        <Tooltip
          position={ COMPONENTS.TOOLTIP.POSITION.TOP }
        >
          { text.join(', ') }
        </Tooltip>
      </span>
    );
  };

  renderShortCompaniesName = () => {
    const { employee: { Companies, AccountId } } = this.state;

    if (!Companies || !Companies.length) {
      return null;
    }

    return (
      <ul className={ styles.list }>
        {Companies.map(({ CompanyId, CompanyName, ShortCompanyName, HasDebt, AvailableAmount }) => (
          <li className={ `${styles.item} ${styles.trip_link}` } key={ CompanyId }>
            <LinkToNewTab
              to={ `/account/${AccountId}/company/${CompanyId}/account/profile` }
            >
              { this.handleCompanyName(ShortCompanyName, CompanyName, HasDebt, AvailableAmount) }
            </LinkToNewTab>
          </li>
        ))}
      </ul>
    );
  };

  renderDepartmentsItems = departments => departments.map((department) => {
    if (department && department.HeadOfDepartmentEmployee) {
      const { Name, Patronymic, Surname, Id } = department.HeadOfDepartmentEmployee;
      const head = `(${LABELS.HEAD} - ${getShortName({ Surname, Name, Patronymic })})`;

      return (
        <li className={ styles.item } key={ `${department.Id} ${Id}` }>
          {department.Name} <LinkToNewTab to={ `/employeePage/${Id}` }>{ head }</LinkToNewTab>
        </li>
      );
    }

    return (
      <li className={ styles.item } key={ department.Id }>
        {department.Name}
      </li>
    );
  });

  renderDepartments = () => {
    const { employee: { Companies } } = this.state;

    if (!Companies && !Companies.length) {
      return null;
    }

    return (
      <ul className={ styles.list }>
        {Companies.map(({ Departments }) => this.renderDepartmentsItems(Departments))}
      </ul>
    );
  };

  renderTripTags = () => {
    const { employee: { Tags } } = this.state;
    if (!Tags.length) {
      return null;
    }

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1-2'] }>
          <label>{TITLE.EMPLOYEE_TAGS}</label>
        </div>
        <div className={ styles['col-1-3'] }>
          <ul className={ styles.list }>
            {Tags.map(({ Id, Value }) => (
              <li className={ styles.item } key={ Id }>{Value}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  renderProject = (projectName, headEmployee) => {
    if (headEmployee) {
      const { Surname, Name, Patronymic } = headEmployee;
      const projectHead = `(${LABELS.HEAD} - ${getShortName({ Surname, Name, Patronymic })})`;

      return (
        <span>
          { projectName }
          {' '}
          <LinkToNewTab to={ `/employeePage/${headEmployee.Id}` }>{ projectHead }</LinkToNewTab>
        </span>
      );
    }

    return (
      <span>
        { projectName }
      </span>
    );
  };

  handleChange = (value) => {
    if (!maxCommentLength || value.length < maxCommentLength) {
      this.setState({
        notes: value,
      });
    }
  };

  renderNotesLabel = notes => (notes && notes.length ? notes : COMMENTS.Comment.NOCOMMENTTEXT);

  renderCommentText = () => {
    const { employee: { Notes } } = this.state;

    return (
      <div className={ styles['notes-row'] }>
        <div className={ `${styles['col-1-2']} ${styles['note-col']}` }>
          <span>{ this.renderNotesLabel(Notes) }</span>
        </div>
        <div className={ styles['col-1-3'] }>
          <Button
            label={ ACCOUNTTEXT.EDIT }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ this.handleEditing }
            className={ styles['edit-button'] }
          />
        </div>
      </div>
    );
  };

  renderCommentEditing = () => {
    const { waitingResponse, notes } = this.state;

    return (
      <CommentComponent
        value={ notes }
        placeholder={ commentPlaceholder }
        limit={ maxCommentLength }
        waitingResponse={ waitingResponse }
        onChange={ this.handleChange }
        onClose={ this.closeEditing }
        onSave={ this.handleSave }
      />
    );
  };

  renderGeneral = () => {
    const { employee, rights, citizenship, projects, editing } = this.state;
    const {
      Surname,
      Name,
      Status,
      Patronymic,
      Code,
      Birthday,
      EmployeeNumber,
      Sex,
      ProjectId,
    } = employee;
    const patronymic = Patronymic || '';
    const employeeBirthday = moment(Birthday).locale('ru').format('ll');
    const employeeSex = Sex === 'Man' ? 'Мужской' : 'Женский';
    const canLogin = rights ? rights.canLogin : null;
    const countryName = getCountryName(citizenship, Code);
    const findProject = ProjectId && projects.length && projects.find(({ Id }) => Id === ProjectId);
    const projectName = findProject ? findProject.Name : '';

    return (
      <div id='general' className={ styles.col1 } ref={ ref => this.setRef(ANCHORS.GENERAL, ref) }>
        <div className={ styles.title }>
          {TITLE.COMMON}
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.EMPLOYEE_NAME}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span
              className={ `sw-tooltip-wrapper ${styles.clickable} ${styles['user-name']}` }
            >
              { Surname } { Name } { patronymic }
              { this.renderAddIcon(Status, canLogin) }
            </span>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.DATE_BIRTHDAY}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span>{ employeeBirthday }</span>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.NATIONALITY}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span>{ countryName }</span>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.EMPLOYEE_SEX}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span>{ employeeSex }</span>
          </div>
        </div>
        {EmployeeNumber && <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.EMPLOYEE_NUMBER}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span>{ EmployeeNumber }</span>
          </div>
        </div>}
        {this.renderTripTags()}
        {ProjectId && <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.PROJECT}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            { this.renderProject(projectName, findProject.ResponsibleEmployee) }
          </div>
        </div>}
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.NOTES}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            { editing ? this.renderCommentEditing() : this.renderCommentText()}
          </div>
        </div>
      </div>
    );
  }

  renderAccounts = () => {
    const { employee: { AccountName, AccountId, Companies } } = this.state;
    const { CompanyId } = Companies[0];
    const nameHtml = AccountName ? `${AccountName}(${AccountId})` :
    <span className={ styles.nodicated } > { `${ACCOUNTTEXT.NOINDICATED}(${AccountId})` } </span>;

    return (
      <div id='accounts' className={ styles.col1 } ref={ ref => this.setRef(ANCHORS.ACCOUNTS, ref) }>
        <div className={ styles.title }>
          {TITLE.ACCOUNTS}
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.ACCOUNT_NAME}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <LinkToNewTab to={ `/account/${AccountId}/company/${CompanyId}/account/profile` }>
              <span>{ nameHtml }</span>
            </LinkToNewTab>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.SHORT_COMPANY_LIST}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            { this.renderShortCompaniesName() }
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.DEPARTMENTS}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            { this.renderDepartments() }
          </div>
        </div>
      </div>
    );
  }

  renderContacts = () => {
    const { employee: { Email, MobilePhone } } = this.state;

    return (
      <div id='contacts' className={ styles.col1 } ref={ ref => this.setRef(ANCHORS.CONTACTS, ref) }>
        <div className={ styles.title }>
          {TITLE.EMPLOYEECONTACTS}
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.EMAIL}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <a href={ `mailto:${Email}` }>{ Email }</a>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{TITLE.PHONE_NUMBER}</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span>{ MobilePhone }</span>
          </div>
        </div>
      </div>
    );
  }

  renderStickyPanel = () => {
    const { rights, employee: { Bonuses } } = this.state;

    return (
      <div className={ styles.sticky }>
        <StickyPanel
          adjustment={ 120 }
        >
          <div className={ styles['menu-anchor'] }>
            <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.GENERAL) }>Общее</a></span>
            <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.CONTACTS) }>Контакты</a></span>
            <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.ACCOUNTS) } >Аккаунты</a></span>
            <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.DOCUMENT) }>Документы</a></span>
            <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.TP) }>Travel политики</a></span>
            {rights && <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.RIGHT) }>Права</a></span>}
            {rights && rights.buyApprovalScheme && <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.SCHEME) }>Схема согласования</a></span>}
            {Bonuses.length ?
              <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.BONUS_CARDS) }>Бонусные Карты</a></span>
              : null
            }
            <span><a className={ styles['menu-anchor__item'] } onClick={ this.handleScroll(ANCHORS.TRIP) }>Поездки</a></span>
          </div>
        </StickyPanel>
      </div>
    );
  }

  renderBonuses = (bonuses, bonusCodes) => {
    if (!bonuses.length) {
      return null;
    }
    return (
      <EmployeeBonusCards
        bonuses={ bonuses }
        bonusCodes={ bonusCodes }
        setRef={ this.setRef }
      />
    );
  }

  render() {
    const {
      employee,
      loading,
      travelPolicy,
      rights,
      bonusCodes,
      trips,
      approvalScheme,
      approversForEmployee,
      сreateReportsApproval,
      approvalSchemaER,
    } = this.state;
    const {
      employeeService,
      approvalService,
      appService,
      travelPolicyService,
    } = this.props;
    const { Email: email, Id: id, Bonuses, AccountId, Companies } = employee;

    const tpName = travelPolicy ? travelPolicy.Name : '';

    if (loading) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.wrap }>
        <div className={ styles.content } >
          {this.renderGeneral()}
          {this.renderContacts()}
          {this.renderBonuses(Bonuses, bonusCodes)}
          {this.renderAccounts()}
          <EmployeeDocuments
            employee={ employee }
            setRef={ this.setRef }
          />
          <EmployeeTP
            travelPolicy={ travelPolicy }
            setRef={ this.setRef }
          />
          <EmploeeRights
            employeeService={ employeeService }
            rights={ rights }
            approversForEmployee={ approversForEmployee }
            approvalService={ approvalService }
            approvalScheme={ approvalScheme }
            approvalSchemaER={ approvalSchemaER }
            сreateReportsApproval={ сreateReportsApproval }
            travelPolicyService={ travelPolicyService }
            email={ email }
            id={ id }
            tpName={ tpName }
            setRef={ this.setRef }
          />
          <EmployeeScheme
            approvalService={ approvalService }
            appService={ appService }
            rights={ rights }
            approvalScheme={ approvalScheme }
            setRef={ this.setRef }
          />
          <EmployeeTrips
            setRef={ this.setRef }
            trips={ trips }
            accountId={ AccountId }
            companyId={ Companies[0].CompanyId }
          />
        </div>
        {this.renderStickyPanel()}
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <EmployeeComponent
      employeeService={ opts.employeeService }
      appService={ opts.appService }
      travelPolicyService={ opts.travelPolicyService }
      rightsService={ opts.rightsService }
      approvalService={ opts.approvalService }
      enumsService={ opts.enumsService }
      tripTagsService={ opts.tripTagsService }
    />
  );

  return root;
};

export default renderComponents;
