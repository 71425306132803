import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AddButton } from './Buttons/AddButton';
import { EditButton } from './Buttons/EditButton';
import { DeleteButton } from './Buttons/DeleteButton';
import { ModalDialog } from './ModalDialog';

import { DOCUMENTS, TYPE_PATH } from '../../../bi/constants/documentsConstructor';

import styles from '../styles/index.module.css';

const LABELS = {
  ACTS: 'Акты выполненных работ',
  INVOICES: 'Счет-фактуры',
  ACTS_BUTTON_TEXT: 'Добавить акт',
  INVOICES_BUTTON_TEXT: 'Добавить счет-фактуру',
  ACTS_TOOLTIP: 'Сначала удалите услуги из актов',
  INVOICES_TOOLTIP: 'Сначала удалите услуги из счет-фактур',
  NAME: 'Наименование',
  ACTS_TYPES_SERVICES: 'Услуги в актах',
  INVOICES_TYPES_SERVICES: 'Услуги в счет-фактурах',
  ACTIONS: 'Действия',
  ACTS_MODAL_TEXT: 'Вы действительно хотите удалить акт?',
  INVOICES_MODAL_TEXT: 'Вы действительно хотите удалить счет-фактуру?',
};

const Table = ({
  documentsConstructorService,
  onMoveToNewDocument,
  onMoveToEditDocument,
  id,
}) => {
  const { Settings, NotAddedOperations } = documentsConstructorService.get()[id];

  const [showDialog, setShowDialog] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(null);

  const isDisabled = !NotAddedOperations.length;

  const title = LABELS[id.toUpperCase()];
  const buttonText = id === DOCUMENTS.ACTS ? LABELS.ACTS_BUTTON_TEXT : LABELS.INVOICES_BUTTON_TEXT;
  const tooltipText = id === DOCUMENTS.ACTS ? LABELS.ACTS_TOOLTIP : LABELS.INVOICES_TOOLTIP;
  const typeServices = id === DOCUMENTS.ACTS ? LABELS.ACTS_TYPES_SERVICES : LABELS.INVOICES_TYPES_SERVICES;
  const modalText = id === DOCUMENTS.ACTS ? LABELS.ACTS_MODAL_TEXT : LABELS.INVOICES_MODAL_TEXT;

  const handleCurrentDocId = (index) => {
    setCurrentDocId(index);
    setShowDialog(true);
  };

  const handleCloseDialog = () => setShowDialog(false);

  const handleRemove = () => {
    documentsConstructorService.removeDocument(currentDocId, id);
    handleCloseDialog();
  };

  const renderRows = () => (
    Settings.map(({ Id, Name, ServiceOperations }) => (
      <div className={ styles.row } key={ Id }>
        <div className={ `${styles.col_1} ${styles.text}` }>
          { Name }
        </div>
        <div className={ styles.col_2 }>
          { ServiceOperations.map(({ ServiceType, ServiceOperationNames }) => (
            <div
              key={ ServiceType }
              className={ styles.cell }
            >
              { ServiceOperationNames.map(({ name }) => (
                <div
                  key={ name }
                  className={ styles.text }
                >
                  { name }
                </div>
              )) }
            </div>
          )) }
        </div>
        <div className={ styles.col_3 }>
          <EditButton onMoveToEditDocument={ () => onMoveToEditDocument(TYPE_PATH.EDIT, Id) } />
          <DeleteButton onHandleClick={ () => handleCurrentDocId(Id) } />
        </div>
      </div>
    ))
  );

  const renderDialog = () => {
    if (!showDialog) {
      return null;
    }

    return (
      <ModalDialog
        showDialog={ showDialog }
        onHandleCloseDialog={ handleCloseDialog }
        text={ modalText }
        onHandleRemove={ handleRemove }
      />
    );
  };

  return (
    <div className={ styles.table_wrap }>
      <div className={ styles.header }>
        <div className={ styles.title }>
          { title }
        </div>
        <AddButton
          buttonText={ buttonText }
          tooltipText={ tooltipText }
          disabled={ isDisabled }
          onMoveToNewDocument={ onMoveToNewDocument }
        />
      </div>
      <div className={ styles.table }>
        <div className={ styles.head }>
          <div className={ styles.col_1 }>
            { LABELS.NAME }
          </div>
          <div className={ styles.col_2 }>
            { typeServices }
          </div>
          <div className={ styles.col_3 }>
            { LABELS.ACTIONS }
          </div>
        </div>
        { renderRows() }
      </div>
      { renderDialog() }
    </div>
  );
};

Table.propTypes = {
  documentsConstructorService: PropTypes.object.isRequired,
  onMoveToNewDocument: PropTypes.func.isRequired,
  onMoveToEditDocument: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export { Table };
