import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { SettingButton } from '../documentsConstructor/components/Buttons/SettingButton';
import { DocumentSetup } from '../documentsConstructor/components/DocumentSettings/DocumentSetup';
import { OtherSettings } from '../documentsConstructor/components/OtherSettings';
import { CancelButton } from '../documentsConstructor/components/Buttons/CancelButton';
import { SaveButton } from '../documentsConstructor/components/Buttons/SaveButton';

import { DOCUMENTS } from '../../bi/constants/documentsConstructor';

import styles from '../documentsConstructor/styles/index.module.css';

const EditDocument = ({
  documentsConstructorService,
  goBack,
  documentId,
  tabId,
}) => {
  const capitalizedTabId = tabId.charAt(0).toUpperCase() + tabId.slice(1);

  const { Settings, NotAddedOperations } = documentsConstructorService.get()[capitalizedTabId];
  const document = Settings.find(({ Id }) => Id === documentId);
  const copyDocument = { ...document };

  const [settings, setSettings] = useState(copyDocument);
  const [notAddedOperations, setNotAddedOperations] = useState(NotAddedOperations);

  const handleSaveDocument = () => {
    if (settings.ServiceOperations.length) {
      documentsConstructorService.saveDocument({ capitalizedTabId, settings, notAddedOperations });
      goBack();
    }
  };

  const renderOtherSettings = () => {
    if (DOCUMENTS.ACTS !== capitalizedTabId) {
      return null;
    }

    return (
      <OtherSettings
        settings={ settings }
        onChangeSettings={ setSettings }
      />
    );
  };

  return (
    <div className={ styles.main_wrap }>
      <div className={ styles.document_setup_wrap }>
        <SettingButton
          onGoBack={ goBack }
        />
        <DocumentSetup
          settings={ settings }
          onChangeSettings={ setSettings }
          notAddedOperations={ notAddedOperations }
          onChangeNotAddedOperations={ setNotAddedOperations }
          tabId={ capitalizedTabId }
        />
        { renderOtherSettings() }
      </div>
      <div className={ styles.buttons_wrap }>
        <CancelButton
          onGoBack={ goBack }
        />
        <SaveButton
          onHandleSaveDocument={ handleSaveDocument }
          disabled={ !settings.ServiceOperations.length }
        />
      </div>
    </div>
  );
};

EditDocument.propTypes = {
  documentsConstructorService: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  documentId: PropTypes.number.isRequired,
  tabId: PropTypes.string.isRequired,
};

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <EditDocument
      { ...opts }
    />
  );

  return root;
};

export default renderComponents;
