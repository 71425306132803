import React from 'react';
import PropTypes from 'prop-types';

import { Services } from '../Services/Services';
import { InvoicesSetting } from './components/InvoicesSetting';
import { Table } from '../Table';

import styles from '../../styles/index.module.css';

const Invoices = ({
  documentsConstructorService,
  onMoveToNewDocument,
  onMoveToEditDocument,
  id,
}) => (
  <div className={ styles.content_wrap }>
    <InvoicesSetting
      documentsConstructorService={ documentsConstructorService }
    />
    <Services
      documentsConstructorService={ documentsConstructorService }
      id={ id }
    />
    <Table
      documentsConstructorService={ documentsConstructorService }
      onMoveToNewDocument={ onMoveToNewDocument }
      onMoveToEditDocument={ onMoveToEditDocument }
      id={ id }
    />
  </div>
);

Invoices.propTypes = {
  documentsConstructorService: PropTypes.object.isRequired,
  onMoveToNewDocument: PropTypes.func.isRequired,
  onMoveToEditDocument: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export { Invoices };
