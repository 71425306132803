import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'sw-ui';

import styles from '../../../styles/settings.module.css';

const LABELS = {
  SETTING_VAT: 'Настройка НДС',
  SHOW_VAT: 'Отображать столбец НДС',
  NOT_SHOW_VAT: 'Не выводить столбец НДС',
  NOT_FILL_VAT: 'Не заполнять столбец НДС',
  SPLIT_VAT: 'Разделять ставки НДС (10 и 20%)',
};

const SettingVAT = ({
  settings,
  onChangeSettings,
}) => {
  const initState = settings.SettingVAT;
  const [val, setVal] = useState(initState);

  const handleChange = (value) => {
    setVal(value);
    onChangeSettings({ ...settings, SettingVAT: value });
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.SETTING_VAT }
      </div>
      <div className={ styles.radio }>
        <RadioButton
          onChange={ () => handleChange(LABELS.SHOW_VAT) }
          checked={ val === LABELS.SHOW_VAT }
          className={ styles.radio_width }
        >
          { LABELS.SHOW_VAT }
        </RadioButton>
        <RadioButton
          onChange={ () => handleChange(LABELS.NOT_SHOW_VAT) }
          checked={ val === LABELS.NOT_SHOW_VAT }
          className={ styles.radio_width }
        >
          { LABELS.NOT_SHOW_VAT }
        </RadioButton>
        <RadioButton
          onChange={ () => handleChange(LABELS.NOT_FILL_VAT) }
          checked={ val === LABELS.NOT_FILL_VAT }
          className={ styles.radio_width }
        >
          { LABELS.NOT_FILL_VAT }
        </RadioButton>
        <RadioButton
          onChange={ () => handleChange(LABELS.SPLIT_VAT) }
          checked={ val === LABELS.SPLIT_VAT }
          className={ styles.radio_width }
        >
          { LABELS.SPLIT_VAT }
        </RadioButton>
      </div>
    </div>
  );
};

SettingVAT.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeSettings: PropTypes.func.isRequired,
};

export { SettingVAT };
