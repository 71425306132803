import React from 'react';
import PropTypes from 'prop-types';

import { SettingVAT } from './components/SettingVAT';
import { SettingAdditionalColumn } from './components/SettingAdditionalColumn';

import styles from '../../styles/settings.module.css';

const OtherSettings = ({
  settings,
  onChangeSettings,
}) => (
  <div className={ styles.settings }>
    <SettingVAT
      settings={ settings }
      onChangeSettings={ onChangeSettings }
    />
    <SettingAdditionalColumn
      settings={ settings }
      onChangeSettings={ onChangeSettings }
    />
  </div>
);

OtherSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeSettings: PropTypes.func.isRequired,
};

export { OtherSettings };
