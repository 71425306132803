import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabsItem } from 'sw-ui';

import { Acts } from './Acts';
import { Invoices } from './Invoices';

import styles from '../styles/index.module.css';

const LABELS = {
  ACTS: 'Акты',
  INVOICES: 'Счет-фактуры',
  REGISTRIES: 'Реестры/Отчеты',
};

const Navigation = ({
  onToAnotherTab,
  documentsConstructorService,
  onMoveToNewDocument,
  onMoveToEditDocument,
  params,
}) => {
  const id = params.tabId;
  const capitalizedId = id.charAt(0).toUpperCase() + id.slice(1);

  const toAnotherTab = (tabId, title) => (
    <div
      className={ styles.tab_link }
      onClick={ () => onToAnotherTab({ tabId }) }
    >
      { title }
    </div>
  );

  return (
    <div>
      <Tabs theme='default' activeTabId={ id } >
        <TabsItem
          id='acts'
          title={ () => toAnotherTab('acts', LABELS.ACTS) }
        >
          <Acts
            documentsConstructorService={ documentsConstructorService }
            onMoveToNewDocument={ onMoveToNewDocument }
            onMoveToEditDocument={ onMoveToEditDocument }
            id={ capitalizedId }
          />
        </TabsItem>
        <TabsItem
          id='invoices'
          title={ () => toAnotherTab('invoices', LABELS.INVOICES) }
        >
          <Invoices
            documentsConstructorService={ documentsConstructorService }
            onMoveToNewDocument={ onMoveToNewDocument }
            onMoveToEditDocument={ onMoveToEditDocument }
            id={ capitalizedId }
          />
        </TabsItem>
        <TabsItem
          id='registries'
          title={ () => toAnotherTab('registries', LABELS.REGISTRIES) }
        >
          { LABELS.REGISTRIES }
        </TabsItem>
      </Tabs>
    </div>
  );
};

Navigation.propTypes = {
  onToAnotherTab: PropTypes.func.isRequired,
  documentsConstructorService: PropTypes.object.isRequired,
  onMoveToNewDocument: PropTypes.func.isRequired,
  onMoveToEditDocument: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

export { Navigation };
